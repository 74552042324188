import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Bounce from 'react-reveal/Bounce'

import Layout from '../components/layout'
import Head from '../components/head'
import ClickListItem from '../components/clickListItem'
import styles from './contact.module.scss'

const ContactPage = () => {
    const { images } = useStaticQuery(graphql`
        query {
            images: allFile(filter: { relativePath: { regex: "/contact//" } }) {
                nodes {
                    childImageSharp {
                        fluid(maxWidth: 250) {
                            ...GatsbyImageSharpFluid
                            originalName
                        }
                    }
                }
            }
        }
    `)

    const findImage = (name) => {
        return images.nodes.find(({ childImageSharp }) => {
            return childImageSharp.fluid.originalName.includes(name)
        }).childImageSharp
    }

    return (
        <Layout>
            <Head title="Contact" />
            <p>You can find me on these platforms as well:</p>

            <div className={styles.container}>
                <Bounce  delay={parseInt(Math.random() * 500)} >
                    <ClickListItem className={styles.item} linkToPlay='https://twitter.com/krearthur1'>
                        <Img fluid={findImage('twitter').fluid} />
                    </ClickListItem>
                </Bounce>
                <Bounce delay={parseInt(Math.random() * 500)}>
                    <ClickListItem className={styles.item} linkToPlay='https://stackoverflow.com/users/1344421/artgrohe'>
                        <Img fluid={findImage('overflow').fluid} />
                    </ClickListItem>
                </Bounce>
                <Bounce delay={parseInt(Math.random() * 500)}>
                    <ClickListItem className={styles.item} linkToPlay='https://github.com/krearthur'>
                        <Img fluid={findImage('github').fluid} />
                    </ClickListItem>
                </Bounce>
                <Bounce delay={parseInt(Math.random() * 500)}>
                    <ClickListItem className={styles.item} linkToPlay='https://www.linkedin.com/in/arthur-grohe-43a0a143/'>
                        <Img fluid={findImage('linkedin').fluid} />
                    </ClickListItem>
                </Bounce>
                <Bounce delay={parseInt(Math.random() * 500)}>
                    <ClickListItem className={styles.item} linkToPlay='https://www.xing.com/profile/Arthur_Grohe'>
                        <Img fluid={findImage('xing').fluid} />
                    </ClickListItem>
                </Bounce>
                <Bounce delay={parseInt(Math.random() * 500)}>
                    <ClickListItem className={styles.item} linkToPlay='https://sketchfab.com/artgrohe'>
                        <Img fluid={findImage('sketch').fluid} />
                    </ClickListItem>
                </Bounce>
            </div>

        </Layout>
    )
}

export default ContactPage
